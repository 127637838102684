<template>
  <div
      v-if="data && data.length > 0"
      class="relative flex items-center w-full  border-#CBCBCB px-4 py-3.5 text-14px leading-6 font-medium text-bw-32"
  >
    <div
        ref="modelRef"
        @click.stop="openList = !openList"
        class="flex items-center justify-between w-full cursor-pointer"
    >
      <p class="text-black text-15px leading-6 font-medium">{{ title }}</p>
      <span class="text-#B3b3b3 w-5.5 h-5.5 i-custom-chevron-down"></span>
    </div>

    <ul
        class="absolute top-full left-0 right-0 w-full border border-#cbcbcb rounded-bl-2 rounded-br-2 overflow-hidden flex flex-col max-h-50 scrollEvent scroll-smooth overflow-y-scroll z-20 bg-white"
        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 1px 0px"
        v-if="openList"
    >
      <template v-for="(item, index) in data">
        <li class="block w-full" @click="openList = false">
          <nuxt-link
              v-if="item?.url.startsWith('http')"
              :to="item.url"
              target="_blank"
              :class="index + 1 == data?.length ? 'border-none' : ''"
              class="p-2 px-4 hover:bg-primary duration-400 cursor-pointer hover:text-white block  boder-#CBCBCB"
          >
            {{ item.name }}
          </nuxt-link>
          <nuxt-link
              v-else
              :to="item.url"
              :class="index + 1 == data?.length ? 'border-none' : ''"
              class="p-2 px-4 hover:bg-primary duration-400 cursor-pointer hover:text-white block  boder-#CBCBCB"
          >
            {{ item.name }}
          </nuxt-link>
        </li>
      </template>
    </ul>
  </div>
  <div v-else class="w-full px-4 py-3.5 text-14px leading-6 font-medium text-bw-32">
    <nuxt-link :to="url" class="text-black text-15px leading-6 font-medium">{{ title }}</nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
    url: {
      type: String
    },
  title: {
    type: String
  }
})
const openList = ref<boolean>(false);
const modelRef = ref<HTMLElement>(null);

onClickOutside(modelRef, () => {
  openList.value = false;
});
</script>

<style></style>
